import React from "react";

import { FloatingMenu } from "@amzn/finergywebcomponents";
import "@amzn/finergywebcomponents/dist/main.css";

let FinergyMenu = (_props: any) => <div />;

export class ErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service.
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong loading Finergy floating menu.</h1>;
    }

    return this.props.children as JSX.Element;
  }
}

function FinergyFloatingMenu() {
  FinergyMenu = FloatingMenu as (props: Parameters<typeof FloatingMenu>[0]) => JSX.Element;

  return (
    <ErrorBoundary>
      <FinergyMenu iconTopMargin={12} iconLeftMargin={15} openMenuTopMargin={56} iconRightMargin={40} />
    </ErrorBoundary>
  );
}

export default FinergyFloatingMenu;
