import React, { useEffect, useState } from "react";

import { Container, Box, SpaceBetween, Button, Spinner, ExpandableSection } from "@cloudscape-design/components";
import ReactMarkdown from "react-markdown";

import { ChatMessageProps } from "./ChatMessage";
import MarkdownCodeBlockHighlighter from "../../../rendering-helpers/MarkdownCodeBlockHighlighter";
import { formatIsoTimestampToLocal, submitFeedbacks } from "../../../utils/ChatMessageUtils";
import finneyLogo from "../../image/FinneyLogo.png";
import FeedbackForm from "../feedback/FeedbackForm";

interface AIMessageProps {
  chatMessageProps: ChatMessageProps;
}

/**
 * Returns the message from Finney
 * @param chatMessageProps
 */
export function AIMessage({ chatMessageProps }: AIMessageProps) {
  const [isSpeaking, setIsSpeaking] = useState<boolean>(chatMessageProps.autoSpeak);
  const [ansCopied, setAnsCopied] = useState<boolean>(false);

  const [showReportFeedbacks, setShowReportFeedbacks] = useState<boolean>(
    chatMessageProps.showReportFeedbacks ?? false
  );
  const [rating, setRating] = useState<string>(chatMessageProps.rating ?? "");
  const [feedbacks, setFeedbacks] = useState<string>(chatMessageProps.feedbacks ?? "");

  // positiveFeedbackSubmitted is used to display a message saying that the feedback is submitted and that stays only for 10sec and state is set to false again
  const [positiveFeedbackSubmitted, setPositiveFeedbackSubmitted] = useState<boolean>(false);
  const [thumbsUp, setThumbsUp] = useState<boolean | undefined>(undefined);
  const [thumbsDown, setThumbsDown] = useState<boolean | undefined>(undefined);
  const [negativeFeedbackSubmitted, setNegativeFeedbackSubmitted] = useState<boolean>(false);

  useEffect(() => {
    setShowReportFeedbacks(chatMessageProps.showReportFeedbacks ?? false);
    setRating(chatMessageProps.rating ?? "");
    setFeedbacks(chatMessageProps.feedbacks ?? "");
    setThumbsUp(thumbsUp ?? chatMessageProps.thumbsUp);
    setThumbsDown(thumbsDown ?? chatMessageProps.thumbsDown);
  }, [chatMessageProps]);

  /**
   * Function to handle positive Feedback. Calls the metadataFeedback handler to save the feedback
   */
  async function handlePositiveFeedback() {
    await submitFeedbacks("submit-feedbacks", chatMessageProps.messageId, "WEB:GOOD", undefined);
    setThumbsDown(false);
    setThumbsUp(true);
    setPositiveFeedbackSubmitted(true);
    setTimeout(() => setPositiveFeedbackSubmitted(false), 10000);
  }

  /**
   * Function to handle negative Feedback. Calls the metadataFeedback handler to save the feedback
   */
  async function handleNegativeFeedback() {
    // submit feedbacks/rating to endpoint
    // "WEB:GOOD", "WEB:RR1", "WEB:RA1", "WEB:RA2", "WEB:RAH"
    await submitFeedbacks("submit-feedbacks", chatMessageProps.messageId, rating, feedbacks);
    // hide the feedbacks box
    setShowReportFeedbacks(false);
    setThumbsUp(false);
    setThumbsDown(true);
    setNegativeFeedbackSubmitted(true);
    setTimeout(() => setNegativeFeedbackSubmitted(false), 10000);
    setFeedbacks("");
    setRating("");
  }

  /**
   * Function to handle the auto speak functionality
   */
  async function handleAutoSpeak() {
    if (!isSpeaking) {
      setIsSpeaking(true);
      let msgToRead = chatMessageProps.message;
      if (chatMessageProps.message.includes("=== Answer: ")) {
        msgToRead = chatMessageProps.message.split("=== Answer: ")[1];
      }
      const utterance = new SpeechSynthesisUtterance(msgToRead);
      speechSynthesis.speak(utterance);
    } else {
      speechSynthesis.cancel();
      setIsSpeaking(false);
    }
  }
  return (
    <Container data-testid="ai-message">
      {chatMessageProps.message?.length === 0 ? (
        <Box>
          <Spinner />
        </Box>
      ) : (
        <SpaceBetween alignItems="start" direction="horizontal" size="xs">
          {/* AI avatar */}
          <span>
            <img width={32} src={finneyLogo} alt={chatMessageProps.owner.name} />
          </span>
          {/* AI message */}
          <SpaceBetween direction="vertical" size="m">
            {/* TODO hardcoded maxWidth=1100 to make it looks nice -- need to refactor */}
            <div style={{ maxWidth: 1100 }}>
              <ReactMarkdown children={chatMessageProps.message} components={{ code: MarkdownCodeBlockHighlighter }} />
            </div>
            {/* Referenced Sources Expandable */}
            {chatMessageProps.referencedSources && chatMessageProps.referencedSources.length > 0 && (
              <ExpandableSection headerText={"Referenced Sources"} defaultExpanded={true}>
                <div style={{ maxWidth: 1000, overflow: "hidden" }}>
                  {chatMessageProps.skill !== "S&A WebMEC Finney" && (
                    <ReactMarkdown
                      children={chatMessageProps.referencedSources.map((c) => `${c}`).join("\n")}
                      components={{ code: MarkdownCodeBlockHighlighter }}
                    />
                  )}
                  {chatMessageProps.staticReferenceSources
                    ? chatMessageProps.staticReferenceSources.map((c: string) => {
                        return (
                          <div>
                            <a href={c} target="_blank" rel="noopener noreferrer">
                              {c}
                            </a>
                            <br />
                          </div>
                        );
                      })
                    : null}
                </div>
              </ExpandableSection>
            )}
            {/* Citations Expandable */}
            {chatMessageProps.citations && chatMessageProps.citations.length > 0 && (
              <ExpandableSection headerText={"Citations"} defaultExpanded={true}>
                <div style={{ maxWidth: 1000, overflow: "hidden" }}>
                  <ReactMarkdown
                    children={chatMessageProps.citations.map((c) => `${c}`).join("\n")}
                    components={{ code: MarkdownCodeBlockHighlighter }}
                  />
                </div>
              </ExpandableSection>
            )}
            <div>
              {/* Icon thumbs-up */}
              <Button
                variant="inline-icon"
                iconName={thumbsUp ? "thumbs-up-filled" : "thumbs-up"}
                onClick={handlePositiveFeedback}
              />
              {positiveFeedbackSubmitted && <span style={{ marginLeft: "10px", color: "green" }}>Submitted!</span>}
              {/* Icon thumbs-down */}
              <Button
                variant="inline-icon"
                iconName={thumbsDown ? "thumbs-down-filled" : "thumbs-down"}
                onClick={() => setShowReportFeedbacks(!showReportFeedbacks)}
              />
              {negativeFeedbackSubmitted && <span style={{ marginLeft: "10px", color: "green" }}>Submitted!</span>}
              <Button
                variant="inline-icon"
                iconName="copy"
                onClick={() => {
                  navigator.clipboard.writeText(chatMessageProps.message);
                  setAnsCopied(true);
                  setTimeout(() => setAnsCopied(false), 10000);
                }}
              />
              {ansCopied && <span style={{ marginLeft: "10px", color: "green" }}>Copied!</span>}
              <Button
                variant="inline-icon"
                iconName={isSpeaking ? "audio-off" : "audio-full"}
                onClick={handleAutoSpeak}
              />
              <span>On: {formatIsoTimestampToLocal(chatMessageProps.timestamp)}</span>
            </div>
            {/* Feedbacks box - hidden - only show when user clicks the feedback button */}
            <FeedbackForm
              showReportFeedbacks={showReportFeedbacks}
              chatMessage={chatMessageProps}
              rating={rating}
              feedbacks={feedbacks}
              onRatingChange={setRating}
              onFeedbackChange={setFeedbacks}
              handleSubmit={handleNegativeFeedback}
            />
          </SpaceBetween>
        </SpaceBetween>
      )}
    </Container>
  );
}
export default AIMessage;
